<template>
  <v-app-bar app color="background" elevation="0">
    <v-btn
      active-class=""
      color="transparent"
      class="white--text ma-2"
      plain
      :to="{ name: 'app-dashboard' }"
      large
    >
      <v-img :src="require('@/assets/logo.png')" width="80px" contain></v-img>
    </v-btn>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            <v-icon> mdi-bell-outline </v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.notifications") }}</span>
      </v-tooltip>
      <v-btn text @click="updateDrawer">
        <v-icon left> mdi-account-circle</v-icon>
        <span class="pr-1 text-body-2 text-capitalize"> {{ user.firstName }} </span>
        <v-icon right> mdi-menu-down </v-icon>
      </v-btn>
    </v-toolbar-items>
  </v-app-bar>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      search: "",
      closeOnClick: false
    };
  },
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    user() {
      return this.$auth.user() || {};
    }
  },
  methods: {
    updateDrawer() {
      Event.$emit("app-drawer-update", !this.drawer);
    }
  }
};
</script>
